import { useState, useEffect } from 'react'
import facebookIcon from "../../assets/images/facebook-link.png"
import instagramIcon from "../../assets/images/instagram-link.png"
import kwaiIcon from "../../assets/images/kwai-link.png"
import linkedinIcon from "../../assets/images/linkedin-link.png"
import tiktokIcon from "../../assets/images/tik-tok-link.png"
import twitterIcon from "../../assets/images/twitter-link.png"
import youtubeIcon from "../../assets/images/youtube-link.png"
import ArrowLeft from '../../assets/images/arrow-left.svg'
import ArrowRight from '../../assets/images/arrow-right.svg'
import WOW from 'wowjs';
import useSWR from 'swr';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const getlinkIcon = link => {
  switch (link) {
    case 'facebook':
      return facebookIcon;
    case 'instagram':
      return instagramIcon;
    case 'kwai':
      return kwaiIcon;
    case 'linkedin':
      return linkedinIcon;
    case 'tiktok':
      return tiktokIcon;
    case 'twitter':
      return twitterIcon;
    case 'youtube':
      return youtubeIcon;
      
      default:
      return facebookIcon;
  }
}

const baseUrl = 'https://admin.somoscomuu.com/wp-json/wp/v2/talents?order=asc&orderby=title&per_page=6';
let totalPages = 0;
const fetcher = (...args) => fetch(...args).then(res => {
  for(let entry of res.headers.entries()) {
    if (entry[0] === "x-wp-totalpages") {
      totalPages = Number(entry[1]);
    }
  }
  return res.json()
});

const TalentoSlider = ({ query }) => {
  const [value, setValue] = useState(0)
  const [step, setStep] = useState(1)
  const { data, error } = useSWR(`${baseUrl}&page=${step}&search=${query}`, fetcher)
  const handleBack = () => {
    if (value > 0) {
      setValue(prev => prev - 1)
    } else {
      if (step > 1) {
        setStep(prev => prev - 1)
        setValue(5)
      }
    }
  }
  const handleNext = () => {
    if (value < 5) {
      if (value < data.length - 1) {
        setValue(prev => prev + 1)
      }
    } else {
      if (step < totalPages) {
        setStep(prev => prev + 1)
        setValue(0)
      }
    }
  }
  useEffect(() => {
    setValue(0)
    setStep(1)
  }, [query])
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  if (error) return <div>failed to load</div>
  if (!data) return <div className="commu-main-marcas__talentos__contentload">
    <Loader
      type="Bars"
      color="#070809"
      height={100}
      width={100}
    />
  </div>
  const slides = data.map(talent => ({
    id: talent.id,
    imgSrc: talent.talent_meta.avatar,
    thumbnailSrc: talent.talent_meta.avatar,
    alt: 'avatar',
    firstname: talent.talent_meta.firstname,
    lastname: talent.talent_meta.lastname,
    job: talent.talent_meta.job,
    description: talent.talent_meta.description,
    social_links: talent.talent_meta.social_links
  }))
  if (!slides.length) return <div>No existen talentos con ese nombre</div>
  return (
    <>
      <div className="commu-main-marcas__talentos__content">
        <p className="commu-main-marcas__talentos__content__fullname">
          <span>
            {slides[value].firstname}
            {' '}
          </span>
          {slides[value].lastname}
        </p>
        <button className="button-left" onClick={handleBack}>
          <img src={ArrowLeft} alt="left" />
        </button>
        <div className="commu-main-marcas__talentos__content__profile">
          <div className="commu-main-marcas__talentos__content__profile__cover">
            <img src={slides[value].imgSrc} alt={slides[value].alt} className={'wow slideInDown'} />
          </div>
          <div className="commu-main-marcas__talentos__content__profile__info wow slideInRight">
            <p className="commu-main-marcas__talentos__content__profile__info_mobile_name wow fadeIn">
              {`${slides[value].firstname} ${slides[value].lastname}`}
            </p>
            <p className="commu-main-marcas__talentos__content__profile__info_job wow fadeIn">
              {slides[value].job}
            </p>
            <p className="commu-main-marcas__talentos__content__profile__info_description wow fadeIn">
              {slides[value].description}
            </p>
            <div className="commu-main-marcas__talentos__content__profile__info_links">
              {slides[value].social_links.map((social, index) => (
                <a key={index} href={social.link} target='_blank' rel='noreferrer'>
                  <img src={getlinkIcon(social.name)} alt='social' />
                </a>
              ))}
            </div>
          </div>
        </div>
        <button className="button-right" onClick={handleNext}>
          <img src={ArrowRight} alt="right"/>
        </button>
      </div>
      <div className="commu-main-marcas__talentos__slider">
        <div className={`commu-main-marcas__talentos__slider__thumbnails`}>
          {slides.map((element, index) => (
            <div
              className={value === index ? 'active' : ''}
              key={element.id}
              onClick={() => setValue(index)}
            >
              <div className="border" />
              <img src={element.thumbnailSrc} alt={element.alt} className={'wow slideInLeft'} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TalentoSlider
