import React from 'react';
import '../../components/Button/Button.scss';
import Breadcrumb from '../../components/Breadcrumb';
import iconFb from '../../assets/images/icon-fb.svg';
import iconIns from '../../assets/images/icon-instagram.svg';
import iconLink from '../../assets/images/icon-linkedin.svg';
import logoContacto from '../../assets/images/logo-contacto.svg';
import thumb from '../../assets/images/thumb-marcas-contacto.png';
import Onfire from '../../assets/images/onfire.svg';
import Back from '../../assets/images/arrow-left-white.svg';

const Contacto = ({ view, handleView }) => {

  const SplashContacto = () => {
    return (
      <div className="commu-main-marcas__contacto">
        <Breadcrumb parent={'Marcas'} title={'Contacto'} />
        <div className="commu-main-marcas__contacto__wrapper">
          <h2>Únete a</h2>
          <img src={logoContacto} alt="" />
          <ul>
            <li>
              <a href="https://facebook.com">
                <img src={iconFb} alt="" />
              </a>
            </li>
            <li>
              <a href="https://instagram.com">
                <img src={iconIns} alt="" />
              </a>
            </li>
            <li>
              <a href="https://linkedin.com">
                <img src={iconLink} alt="" />
              </a>
            </li>
          </ul>
          <p>
            <a href="mailto:marcas@somoscomuu.com">marcas@somoscomuu.com</a>
          </p>
          <div
            className="commu-button-default"
            style={{ width: '200px' }}
            onClick={(e) => handleView(e)}
          >
            Escríbenos
          </div>
          <a
            className="commu-main-marcas__contacto__onfire"
            href="https://www.onfire.co/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Powered by:</p>
            <img src={Onfire} alt='onfire' />
          </a>
        </div>
      </div>
    );
  };

  const FormContacto = () => {
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({
      fullname: '',
      company: '',
      email: '',
      phone: '',
      services: '',
      talents: '',
      requirements: ''
    })
    const handleChange = ev => {
      setData(prev => ({ ...prev, [ev.target.name]: ev.target.value }))
    }
    const sendContact = async (ev) => {
      ev.preventDefault()
      setLoading(true)
      const res = await fetch('https://admin.somoscomuu.com/wp-json/custom/v1/saveContactBrand', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
      const { send, post_id } = await res.json()
      if (post_id && send) {
        setData({
          fullname: '',
          company: '',
          email: '',
          phone: '',
          services: '',
          talents: '',
          requirements: ''
        })
        alert('Recibimos la información')
      } else {
        alert('No se pudo completar el envío')
      }
      setLoading(false)
    }
    return (
      <>
        <div className="commu-main-marcas__contacto__form">
          <div className="commu-main-marcas__contacto__form__wrapper">
            <Breadcrumb parent={'Marcas'} title={'Contacto'} color={'#fff'} />
            <div className="commu-main-marcas__contacto__form__wrapper__container">
              <div className="commu-main-marcas__contacto__form__wrapper__container__formarea">
                <form>
                  <p onClick={(e) => handleView(e)}>
                    <img src={Back} alt="" />
                    Atrás
                  </p>
                  <h2>Datos de contacto</h2>
                  <input
                    type="text"
                    placeholder="Nombres y Apellidos"
                    name="fullname"
                    value={data.fullname}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Empresa"
                    name="company"
                    value={data.company}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                  />
                  <input
                    type="tel"
                    placeholder="Teléfono"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                  />
                  <h2>SERVICIOS INTERESADO</h2>
                  <input
                    type="text"
                    placeholder="SERVICIOS"
                    name="services"
                    value={data.services}
                    onChange={handleChange}
                  />
                  <h2>¿INTERESADO EN ALGÚN TALENTO EN PARTICULAR?</h2>
                  <input
                    type="text"
                    placeholder="TALENTOS"
                    name="talents"
                    value={data.talents}
                    onChange={handleChange}
                  />
                  <h2>REQUERIMIENTOS</h2>
                  <input
                    type="text"
                    placeholder="REQUERIMIENTOS"
                    name="requirements"
                    value={data.requirements}
                    onChange={handleChange}
                  />
                  <button onClick={sendContact}>
                    {loading ? 'Enviando' : 'Enviar'}
                  </button>
                </form>
              </div>
              <div className="commu-main-marcas__contacto__form__wrapper__container__thumb">
                <img src={thumb} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{view === 0 ? <SplashContacto /> : <FormContacto />}</>;
};

export default Contacto;
