import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import QueHacemos from './QueHacemos';
import QueNoSomos from './QueNoSomos';
import QueSomos from './QueSomos';
import Servicios from './Servicios';
import Contacto from './Contacto';
import WOW from 'wowjs';

import './Talentos.scss';

const Talentos = (props) => {

  const { location: { pathname } } = props;

  const [items, setItems] = useState([]);
  const [view, setView] = useState('que-somos');
  const [color, setColor] = useState('D06852');
  const [viewContacto, setViewContacto] = useState(0);

  const handleViewContacto = (e) => {
    e.preventDefault();
    if (viewContacto === 0) {
      setViewContacto(1);
    } else {
      setViewContacto(0);
    }
  };

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
    let menuItems = [
      { name: '¿Qué somos?', slug: '/talentos/que-somos' },
      { name: '¿Qué HACEMOS?', slug: '/talentos/que-hacemos' },
      { name: 'Servicios', slug: '/talentos/servicios' },
      { name: '¿Qué NO somos?', slug: '/talentos/que-no-somos' },
      { name: 'Contacto', slug: '/talentos/contacto' }
    ];
    setItems(menuItems);
    let slug = pathname.split('/');
    if (slug.length >= 3) {
      setView(slug[2]);
      switch (slug[2]) {
        case 'que-somos':
          setColor('D06852')
          break
        case 'que-hacemos':
          setColor('4E61A6')
          break
        case 'servicios':
          setColor('9597C8')
          break
        case 'que-no-somos':
          setColor('D5D250')
          break
        case 'contacto':
          setColor(viewContacto === 1 ? 'fff' : '070809')
          break
        default:
          setColor('D06852')
      }
    }
  }, [pathname, viewContacto]);
  return (
    <>
      <Header
        items={items}
        pathname={pathname}
        color={color}
        isBlack={pathname==='/talentos/contacto' && viewContacto === 1}
      />
      <main className="commu-main-talentos wow fadeIn">
        {
          view && view === 'que-somos' ? (
            <QueSomos />
          ) : ''
        }
        {
          view && view === 'que-hacemos' ? (
            <QueHacemos />
          ) : ''
        }
        {
          view && view === 'servicios' ? (
            <Servicios />
          ) : ''
        }
        {
          view && view === 'que-no-somos' ? (
            <QueNoSomos />
          ) : ''
        }
        {
          view && view === 'contacto' ? (
            <Contacto view={viewContacto} handleView={handleViewContacto} />
          ) : ''
        }
      </main>
      <Footer
        view={view}
        section='talentos'
        isBlack={pathname==='/talentos/contacto' && viewContacto === 1}
      />
    </>
  );
};

export default Talentos;
