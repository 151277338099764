import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb1 from '../../assets/images/thumb-contenido-1.png';
import thumb2 from '../../assets/images/thumb-contenido-2.png';
import thumb3 from '../../assets/images/thumb-contenido-3.png';
import thumb4 from '../../assets/images/thumb-contenido-4.png';
import thumb5 from '../../assets/images/thumb-contenido-5.png';

const Servicios = () => {
  return (
    <>
      <div className="commu-main-marcas__servicios">
        <Breadcrumb parent={'Marcas'} title={'Servicios'} />
        <h1>CONTENIDOS DIGITALES</h1>
        <p>
          <b>Gracias al asesoramiento, desarrollo y producción de Comuu, <br />
          nuestros talentos</b> conectan de mejor forma con tu marca a través <br />{' '}
          de una estrategia.
        </p>
        <div className="commu-main-marcas__servicios__contenidos">
          <div className="commu-main-marcas__servicios__contenidos__item">
            <div className="commu-main-marcas__servicios__contenidos__item__cover">
              <img src={thumb1} alt="" />
            </div>
            <h3>GENERACIÓN DE CONTENIDO</h3>
            <p>
              De la mano de nuestros talentos, <b>creamos contenido de valor</b> para
              tus marcas. Seguimos <b>tendencias y producimos</b> contenido de alta
              calidad.
            </p>
          </div>
          <div className="commu-main-marcas__servicios__contenidos__item">
            <div className="commu-main-marcas__servicios__contenidos__item__cover">
              <img src={thumb2} alt="" />
            </div>
            <h3>IMAGEN PUBLICITARIA</h3>
            <p>
              Creamos la <b>identidad</b> de tu marca y trabajamos en su
              <b>posicionamiento</b>.
            </p>
          </div>
          <div className="commu-main-marcas__servicios__contenidos__item">
            <div className="commu-main-marcas__servicios__contenidos__item__cover">
              <img src={thumb3} alt="" />
            </div>
            <h3>INFLUENCER MARKETING</h3>
            <p>
              Trabajamos <b>estrategias</b> con nuestros representados, para que
              gracias a su <b>alcance</b> y a su <b>afinidad</b>, tu marca pueda llegar a más
              personas.
            </p>
          </div>
          <div className="commu-main-marcas__servicios__contenidos__item">
            <div className="commu-main-marcas__servicios__contenidos__item__cover">
              <img src={thumb4} alt="" />
            </div>
            <h3>RELACIONES PÚBLICAS</h3>
            <p>
              Contamos con <b>especialistas</b> que trabajarán en <b>amplificar tu
              audiencia.</b>
            </p>
          </div>
          <div className="commu-main-marcas__servicios__contenidos__item">
            <div className="commu-main-marcas__servicios__contenidos__item__cover">
              <img src={thumb5} alt="" />
            </div>
            <h3>ESTRATEGIA DE MARKETING DIGITAL</h3>
            <p>
              Posiciona a tu marca en digital, gracias a una <b>estrategia</b> que
              combina el <b>contenido, mediciones y resultados.</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicios;
