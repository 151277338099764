import React from 'react';

const Logo = (props) => {
  // #070809
  const { color } = props;

  return (
    <>
      <div className="commu-logo">
        <svg
          width="200"
          height="53"
          viewBox="0 0 200 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.713 48.3332C26.6056 48.3332 26.5545 48.2923 26.5341 48.1645C26.4267 47.4383 25.767 46.881 24.821 46.881C23.737 46.881 22.8574 47.7094 22.8574 48.9417C22.8574 50.1587 23.696 51.0025 24.821 51.0025C25.7773 51.0025 26.4164 50.532 26.5341 49.8264C26.5545 49.6883 26.6056 49.6474 26.7335 49.6474H27.7818C27.8687 49.6474 27.9607 49.6985 27.9607 49.8059C27.9607 51.161 26.6875 52.2041 24.8159 52.2041C22.9137 52.2041 21.4205 50.89 21.4205 48.9468C21.4205 47.0139 22.9137 45.6895 24.8159 45.6895C26.8153 45.6895 27.9607 46.9526 27.9607 48.2003C27.9607 48.2718 27.9096 48.3383 27.792 48.3383H26.713V48.3332Z"
            fill={color}
          />
          <path
            d="M36.71 48.9414C36.71 50.9152 35.1248 52.1987 33.2328 52.1987C31.3408 52.1987 29.7556 50.9152 29.7556 48.9414C29.7556 46.9983 31.3408 45.6841 33.2328 45.6841C35.1248 45.6841 36.71 46.9983 36.71 48.9414ZM31.1823 48.9414C31.1823 50.2147 32.118 51.0021 33.2328 51.0021C34.3475 51.0021 35.2833 50.2147 35.2833 48.9414C35.2833 47.6681 34.3475 46.8806 33.2328 46.8806C32.118 46.8806 31.1823 47.6681 31.1823 48.9414Z"
            fill={color}
          />
          <path
            d="M46.2211 45.7864C46.2825 45.7864 46.349 45.8477 46.349 45.9142V51.9686C46.349 52.03 46.2876 52.0965 46.2211 52.0965H45.045C44.9837 52.0965 44.9172 52.0351 44.9172 51.9686V48.113H44.8865C44.8865 48.113 44.7587 48.4301 44.4979 48.788L42.6365 51.4061L40.765 48.788C40.5144 48.4301 40.3866 48.113 40.3866 48.113H40.3559V51.9686C40.3559 52.03 40.2946 52.0965 40.2281 52.0965H39.052C38.9906 52.0965 38.9241 52.0351 38.9241 51.9686V45.9142C38.9241 45.8529 38.9855 45.7864 39.052 45.7864H39.9775C40.0491 45.7864 40.1258 45.8171 40.1769 45.8938L42.6263 49.3198H42.6468L45.0962 45.8938C45.1473 45.8222 45.224 45.7864 45.2956 45.7864H46.2211Z"
            fill={color}
          />
          <path
            d="M53.8198 45.9142C53.8198 45.8529 53.8811 45.7864 53.9476 45.7864H55.1237C55.1851 45.7864 55.2516 45.8477 55.2516 45.9142V49.0795C55.2516 51.1198 53.9681 52.1987 52.0352 52.1987C50.1023 52.1987 48.8188 51.1147 48.8188 49.0795V45.9142C48.8188 45.8529 48.8801 45.7864 48.9466 45.7864H50.1227C50.1841 45.7864 50.2505 45.8477 50.2505 45.9142V49.0693C50.2505 50.3732 50.8897 51.0022 52.0352 51.0022C53.1806 51.0022 53.8198 50.3732 53.8198 49.0693V45.9142Z"
            fill={color}
          />
          <path
            d="M63.1111 52.0913L59.8947 48.8136C59.5572 48.4761 59.1788 48.0056 59.1788 48.0056H59.1481V51.9686C59.1481 52.03 59.0867 52.0965 59.0203 52.0965H57.8441C57.7828 52.0965 57.7163 52.0351 57.7163 51.9686V45.9142C57.7163 45.8529 57.7777 45.7864 57.8441 45.7864H58.8106L61.9963 49.0232C62.344 49.371 62.7327 49.8772 62.7327 49.8772H62.7736V45.9142C62.7736 45.8529 62.8349 45.7864 62.9014 45.7864H64.0775C64.1389 45.7864 64.2054 45.8477 64.2054 45.9142V51.9686C64.2054 52.03 64.144 52.0965 64.0775 52.0965H63.1111V52.0913Z"
            fill={color}
          />
          <path
            d="M68.1786 51.9686C68.1786 52.03 68.1172 52.0965 68.0507 52.0965H66.8746C66.8132 52.0965 66.7468 52.0351 66.7468 51.9686V45.9142C66.7468 45.8529 66.8081 45.7864 66.8746 45.7864H68.0507C68.1121 45.7864 68.1786 45.8477 68.1786 45.9142V51.9686Z"
            fill={color}
          />
          <path
            d="M70.8478 52.0913C70.7864 52.0913 70.72 52.03 70.72 51.9635V45.9142C70.72 45.8529 70.7813 45.7864 70.8478 45.7864H73.5068C75.5062 45.7864 76.9943 47.0136 76.9943 48.9414C76.9943 50.8436 75.7312 52.0965 73.5273 52.0965H70.8478V52.0913ZM72.1518 46.9881V50.8999H73.5273C74.8619 50.8999 75.5676 50.1431 75.5676 48.9465C75.5676 47.75 74.7034 46.9932 73.5068 46.9932H72.1518V46.9881Z"
            fill={color}
          />
          <path
            d="M83.335 51.0022H80.2669L79.8169 51.9584C79.7556 52.0862 79.7249 52.0965 79.597 52.0965H78.3442C78.2829 52.0965 78.2573 52.0556 78.2573 52.0249C78.2573 51.9942 78.2777 51.938 78.2982 51.897L81.264 45.7915H82.3583L85.3242 51.897C85.3446 51.938 85.3651 51.9942 85.3651 52.0249C85.3651 52.0556 85.3446 52.0965 85.2833 52.0965H84.0305C83.9026 52.0965 83.8719 52.0862 83.8106 51.9584L83.335 51.0022ZM80.768 49.8874H82.8492L82.2816 48.7215C82.0618 48.2716 81.8419 47.5966 81.8419 47.5966H81.7805C81.7805 47.5966 81.5606 48.2716 81.3408 48.7215L80.768 49.8874Z"
            fill={color}
          />
          <path
            d="M87.39 52.0913C87.3287 52.0913 87.2622 52.03 87.2622 51.9635V45.9142C87.2622 45.8529 87.3236 45.7864 87.39 45.7864H90.0491C92.0485 45.7864 93.5365 47.0136 93.5365 48.9414C93.5365 50.8436 92.2735 52.0965 90.0695 52.0965H87.39V52.0913ZM88.694 46.9881V50.8999H90.0695C91.4042 50.8999 92.1098 50.1431 92.1098 48.9465C92.1098 47.75 91.2456 46.9932 90.0491 46.9932H88.694V46.9881Z"
            fill={color}
          />
          <path
            d="M99.2483 52.0913C99.187 52.0913 99.1205 52.03 99.1205 51.9635V45.9142C99.1205 45.8529 99.1818 45.7864 99.2483 45.7864H101.907C103.907 45.7864 105.395 47.0136 105.395 48.9414C105.395 50.8436 104.132 52.0965 101.928 52.0965H99.2483V52.0913ZM100.552 46.9881V50.8999H101.928C103.262 50.8999 103.968 50.1431 103.968 48.9465C103.968 47.75 103.104 46.9932 101.907 46.9932H100.552V46.9881Z"
            fill={color}
          />
          <path
            d="M109.041 49.4681V50.8999H112.738C112.799 50.8999 112.886 50.9817 112.886 51.0482V51.9533C112.886 52.0146 112.799 52.1016 112.738 52.1016H107.737C107.675 52.1016 107.609 52.0402 107.609 51.9737V45.9142C107.609 45.8529 107.67 45.7864 107.737 45.7864H112.676C112.738 45.7864 112.825 45.8682 112.825 45.9347V46.8398C112.825 46.9011 112.738 46.9881 112.676 46.9881H109.041V48.2715H112.365C112.426 48.2715 112.513 48.3534 112.513 48.4198V49.3249C112.513 49.3863 112.431 49.4732 112.365 49.4732H109.041V49.4681Z"
            fill={color}
          />
          <path
            d="M117.923 46.9881C117.862 46.9881 117.775 46.9062 117.775 46.8398V45.9347C117.775 45.8733 117.862 45.7864 117.923 45.7864H123.89C123.952 45.7864 124.039 45.8682 124.039 45.9347V46.8398C124.039 46.9011 123.957 46.9881 123.89 46.9881H121.62V51.9686C121.62 52.03 121.559 52.0965 121.492 52.0965H120.316C120.255 52.0965 120.188 52.0351 120.188 51.9686V46.9881H117.923Z"
            fill={color}
          />
          <path
            d="M129.326 51.0022H126.258L125.808 51.9584C125.747 52.0862 125.716 52.0965 125.588 52.0965H124.335C124.274 52.0965 124.248 52.0556 124.248 52.0249C124.248 51.9942 124.269 51.938 124.289 51.897L127.255 45.7915H128.349L131.315 51.897C131.336 51.938 131.356 51.9942 131.356 52.0249C131.356 52.0556 131.336 52.0965 131.274 52.0965H130.021C129.894 52.0965 129.863 52.0862 129.802 51.9584L129.326 51.0022ZM126.764 49.8874H128.845L128.278 48.7215C128.058 48.2716 127.838 47.5966 127.838 47.5966H127.777C127.777 47.5966 127.557 48.2716 127.337 48.7215L126.764 49.8874Z"
            fill={color}
          />
          <path
            d="M134.69 50.8948H137.799C137.861 50.8948 137.947 50.9766 137.947 51.0431V51.9482C137.947 52.0095 137.866 52.0965 137.799 52.0965H133.386C133.325 52.0965 133.258 52.0351 133.258 51.9686V45.9142C133.258 45.8529 133.32 45.7864 133.386 45.7864H134.562C134.624 45.7864 134.69 45.8477 134.69 45.9142V50.8948Z"
            fill={color}
          />
          <path
            d="M141.384 49.4681V50.8999H145.081C145.142 50.8999 145.229 50.9817 145.229 51.0482V51.9533C145.229 52.0146 145.142 52.1016 145.081 52.1016H140.08C140.018 52.1016 139.952 52.0402 139.952 51.9737V45.9142C139.952 45.8529 140.013 45.7864 140.08 45.7864H145.019C145.081 45.7864 145.168 45.8682 145.168 45.9347V46.8398C145.168 46.9011 145.081 46.9881 145.019 46.9881H141.384V48.2715H144.708C144.769 48.2715 144.856 48.3534 144.856 48.4198V49.3249C144.856 49.3863 144.774 49.4732 144.708 49.4732H141.384V49.4681Z"
            fill={color}
          />
          <path
            d="M152.787 52.0913L149.57 48.8136C149.233 48.4761 148.855 48.0056 148.855 48.0056H148.824V51.9686C148.824 52.03 148.763 52.0965 148.696 52.0965H147.52C147.459 52.0965 147.392 52.0351 147.392 51.9686V45.9142C147.392 45.8529 147.453 45.7864 147.52 45.7864H148.486L151.672 49.0232C152.02 49.371 152.408 49.8772 152.408 49.8772H152.449V45.9142C152.449 45.8529 152.511 45.7864 152.577 45.7864H153.753C153.815 45.7864 153.881 45.8477 153.881 45.9142V51.9686C153.881 52.03 153.82 52.0965 153.753 52.0965H152.787V52.0913Z"
            fill={color}
          />
          <path
            d="M155.932 46.9881C155.87 46.9881 155.783 46.9062 155.783 46.8398V45.9347C155.783 45.8733 155.87 45.7864 155.932 45.7864H161.899C161.961 45.7864 162.047 45.8682 162.047 45.9347V46.8398C162.047 46.9011 161.966 46.9881 161.899 46.9881H159.629V51.9686C159.629 52.03 159.567 52.0965 159.501 52.0965H158.325C158.263 52.0965 158.197 52.0351 158.197 51.9686V46.9881H155.932Z"
            fill={color}
          />
          <path
            d="M170.265 48.9414C170.265 50.9152 168.68 52.1987 166.788 52.1987C164.896 52.1987 163.311 50.9152 163.311 48.9414C163.311 46.9983 164.896 45.6841 166.788 45.6841C168.68 45.6841 170.265 46.9983 170.265 48.9414ZM164.737 48.9414C164.737 50.2147 165.673 51.0021 166.788 51.0021C167.902 51.0021 168.838 50.2147 168.838 48.9414C168.838 47.6681 167.902 46.8806 166.788 46.8806C165.673 46.8806 164.737 47.6681 164.737 48.9414Z"
            fill={color}
          />
          <path
            d="M173.379 49.9692C173.507 49.9692 173.568 50.0203 173.589 50.1277C173.696 50.6647 174.146 51.0021 175.143 51.0021C176.151 51.0021 176.606 50.6237 176.606 50.1738C176.606 49.8772 176.396 49.6164 175.767 49.5652L174.126 49.4272C172.842 49.3198 172.162 48.7317 172.162 47.7857C172.162 46.6914 173.118 45.6841 174.98 45.6841C176.902 45.6841 177.746 46.5789 177.746 47.5863C177.746 47.6784 177.674 47.7653 177.547 47.7653H176.503C176.396 47.7653 176.335 47.7346 176.314 47.6068C176.253 47.2386 175.895 46.8806 174.969 46.8806C174.085 46.8806 173.594 47.2386 173.594 47.6988C173.594 47.9852 173.783 48.1948 174.361 48.2357L175.992 48.3738C177.465 48.5016 178.043 49.238 178.043 50.1073C178.043 51.0942 177.189 52.1987 175.153 52.1987C173.231 52.1987 172.167 51.2834 172.167 50.2453V50.1584C172.167 50.051 172.249 49.9692 172.367 49.9692H173.379Z"
            fill={color}
          />
          <path
            d="M52.1528 0.588074H37.7531V14.9877C37.7531 22.929 44.2115 29.3874 52.1528 29.3874C60.0941 29.3874 66.5525 22.929 66.5525 14.9877C66.5525 7.04645 60.0941 0.588074 52.1528 0.588074ZM52.1528 24.6625C46.8194 24.6625 42.478 20.3211 42.478 14.9877V5.31297H52.1528C57.4862 5.31297 61.8276 9.65435 61.8276 14.9877C61.8276 20.3211 57.4862 24.6625 52.1528 24.6625Z"
            fill={color}
          />
          <path
            d="M52.1528 16.9769C51.0534 16.9769 50.1636 16.082 50.1636 14.9877H45.4388C45.4388 18.6899 48.4506 21.7069 52.1579 21.7069C55.8652 21.7069 58.8771 18.695 58.8771 14.9877H54.1522C54.142 16.0871 53.2471 16.9769 52.1528 16.9769Z"
            fill={color}
          />
          <path
            d="M100.547 16.4144V28.83H94.7791V16.4144C94.7791 9.64917 93.0712 5.70665 88.0702 5.70665C83.0691 5.70665 81.3612 9.64917 81.3612 16.4144V28.83H75.5932V16.4144C75.5932 6.12084 79.8272 0.588013 88.065 0.588013C92.3604 0.588013 95.5973 2.35218 97.6529 5.35381C99.7699 2.35218 102.945 0.588013 107.241 0.588013C115.479 0.588013 119.713 6.11573 119.713 16.4144V28.83H113.95V16.4144C113.95 9.64917 112.242 5.70665 107.241 5.70665C102.255 5.70665 100.547 9.64917 100.547 16.4144Z"
            fill={color}
          />
          <path
            d="M130.564 12.9423V0.588013H136.332V12.9423C136.332 20.004 139.451 23.9415 144.922 23.9415C150.394 23.9415 153.513 19.9989 153.513 12.9423V0.588013H159.281V12.9423C159.281 23.3534 153.692 28.8249 144.927 28.8249C136.153 28.83 130.564 23.3585 130.564 12.9423Z"
            fill={color}
          />
          <path
            d="M171.282 12.9423V0.588013H177.051V12.9423C177.051 20.004 180.17 23.9415 185.641 23.9415C191.113 23.9415 194.232 19.9989 194.232 12.9423V0.588013H200V12.9423C200 23.3534 194.411 28.8249 185.646 28.8249C176.872 28.83 171.282 23.3585 171.282 12.9423Z"
            fill={color}
          />
          <path
            d="M15.2383 24.5909C9.5316 24.5909 5.76805 20.1217 5.76805 14.7065C5.76805 9.11741 9.77194 4.82205 15.2383 4.82205C18.7768 4.82205 21.6558 6.55553 23.2921 9.11741L27.7051 6.07998C24.9744 2.42892 20.4848 0 15.2383 0C6.47372 0 0 6.70894 0 14.7065C0 22.7091 6.47372 29.413 15.2383 29.413C21.3541 29.413 26.2835 26.1915 28.7687 21.6864L23.9057 19.1859C22.4432 22.3512 19.2626 24.5909 15.2383 24.5909Z"
            fill={color}
          />
        </svg>
      </div>
    </>
  );
};

export default Logo;
