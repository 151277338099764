import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import logoMobile from '../../assets/images/logo-mobile.svg';
import iconNav from '../../assets/images/icon-menu.svg';
import close from '../../assets/images/icon-close.svg';
import './Header.scss';

const Header = (props) => {
  const { items, color, isBlack = false } = props;
  const [show, setShow] = useState(false);

  const showMenu = () => {
    if (show !== true) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <header className={`commu-header ${isBlack ? 'black' : ''}`}>
        <div className="commu-header__wrapper">
          <Link to="/">
            <Logo color={`#${color}`} />
          </Link>
          <nav className={`commu-header__wrapper__menu _${color}`}>
            <ul>
              {items &&
                items.map((item, index) => (
                  <li
                    key={index}
                    className={props.pathname === item.slug ? 'active' : ''}
                    style={{ color: `#${color}` }}
                  >
                    <Link to={item.slug}>{item.name}</Link>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
        <div className="commu-header__mobile">
          <Link to="/">
            <img src={logoMobile} alt="" />
          </Link>
          <div className="commu-header__mobile__nav">
            <img src={iconNav} alt="" onClick={showMenu} />
          </div>
        </div>
        {show ? (
          <div className="commu-header__mobile__panel">
            <div className="commu-header__mobile__panel__close">
              <img src={close} alt="" onClick={showMenu} />
            </div>
            <ul>
              {items &&
                items.map((item, index) => (
                  <li key={index}>
                    <Link to={item.slug} onClick={showMenu}>{item.name}</Link>
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </header>
    </>
  );
};

export default Header;
