import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb from '../../assets/images/thumb-marcas-1.png';

const QueSomos = () => {
  return (
    <>
      <div className="commu-main-marcas__quesomos">
        <div className="commu-main-talentos__quesomos__col">
          <Breadcrumb parent={'Marcas'} title={'¿Qué somos?'} />
          <h1>UNA COMUUNIDAD DE TALENTOS PARA TU MARCA</h1>
          <p>En Comuu tienes:</p>
          <br />
          <p>1- El talento que elegiremos juntos bajo objetivos concretos y definidos.</p>
          <br />
          <p>2- La estrategia para tus contenidos construída sobre la base de resultados esperados.</p>
        </div>
        <div style={{paddingTop: '50px'}}>
          <img src={thumb} alt="" className="img-full" />
        </div>
      </div>
    </>
  );
};

export default QueSomos;
