import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb1 from '../../assets/images/thumb-estrategia-1.png';
import thumb2 from '../../assets/images/thumb-estrategia-2.png';
import thumb3 from '../../assets/images/thumb-estrategia-3.png';
import thumb4 from '../../assets/images/thumb-estrategia-4.png';

const QueHacemos = () => {
  return (
    <>
      <div className="commu-main-talentos__quehacemos">
        <Breadcrumb parent={'Talentos'} title={'¿Qué hacemos?'} />
        <h1>ESTRATEGIAS DE CONTENIDOS DIGITALES</h1>
        <p>Enfocadas en talentos 100% capacitados por nosotros.</p>
        <div className="commu-main-talentos__quehacemos__estrategias">
          <div className="commu-main-talentos__quehacemos__estrategias__item">
            <img src={thumb1} alt="" className="img-full" />
            <h2>REPRESENTAMOS</h2>
            <p>
              En estos tiempos es importante contar con un <b>buen management</b> que
              te ayude a llegar a más gente, pero sobre todo, a la gente
              adecuada <b>para convertir tu talento en algo rentable.</b>
            </p>
          </div>
          <div className="commu-main-talentos__quehacemos__estrategias__item">
            <img src={thumb2} alt="" className="img-full" />
            <h2>ASESORAMOS</h2>
            <p>
              Te ayudamos en el <b>uso correcto de tu imagen, temas a tocar,
              tendencias</b> que puedes aprovechar, construcción de un <b>estilo</b>
              definido y una <b>estrategia de marca personal</b> que logre posicionarte
              como un creador de contenido relevante.
            </p>
          </div>
          <div className="commu-main-talentos__quehacemos__estrategias__item">
            <img src={thumb3} alt="" className="img-full" />
            <h2>CAPACITAMOS</h2>
            <p>
              Para hacer un <b>uso responsable de las redes</b> y que tu audiencia te
              perciba de la mejor manera. Uso adecuado de <b>herramientas digitales</b>
              e interpretación de medición del contenido que generas.
            </p>
          </div>
          <div className="commu-main-talentos__quehacemos__estrategias__item">
            <img src={thumb4} alt="" className="img-full" />
            <h2>PRODUCIMOS</h2>
            <p>
              Nos encargamos de darle un mayor giro a tus ideas de contenido,
              logrando que tenga un mejor nivel en <b>propuesta visual y valores de
              producción.</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueHacemos;
