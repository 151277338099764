import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb1 from '../../assets/images/thumb-servicio-1.png';
import thumb2 from '../../assets/images/thumb-servicio-2.png';
import thumb3 from '../../assets/images/thumb-servicio-3.png';
import thumb4 from '../../assets/images/thumb-servicio-4.png';

const Servicios = () => {
  return (
    <>
      <div className="commu-main-talentos__servicios">
        <Breadcrumb parent={'Talentos'} title={'Servicios'} />
        <h1 className="wow fadeIn" data-wow-delay=".3s">TE ACOMPAÑAMOS</h1>
        <p>
          Para hacer un mejor uso no solo de tus redes, sino de tu talento. Te
          brindamos asesoría, <br />dirección creativa, equipo para producir tus
          propios contenidos y más.
        </p>
        <div className="commu-main-talentos__servicios__items">
          <div className="commu-main-talentos__servicios__items__item">
            <img src={thumb1} alt="" />
            <h3>
              Manejo y Creación de Imagen, Creación de Contenido (copywriting),
              Community Management.
            </h3>
          </div>
          <div className="commu-main-talentos__servicios__items__item">
            <img src={thumb2} alt="" />
            <h3>
              Estrategia de marketing digital (Logra una mayor audiencia).
            </h3>
          </div>
          <div className="commu-main-talentos__servicios__items__item">
            <img src={thumb3} alt="" />
            <h3>
              Creación de material profesional (Videobook, fotos, web, etc).
            </h3>
          </div>
          <div className="commu-main-talentos__servicios__items__item">
            <img src={thumb4} alt="" />
            <h3>
              Media Kit, asesoría legal y asesoría en relaciones públicas.
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicios;
