import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb1 from '../../assets/images/thumb-quenosomos-1.png';
import thumb2 from '../../assets/images/thumb-quenosomos-2.png';
import thumb3 from '../../assets/images/thumb-quenosomos-3.png';
import thumb4 from '../../assets/images/thumb-quenosomos-4.png';
import thumb5 from '../../assets/images/thumb-quenosomos-5.png';
import thumb6 from '../../assets/images/thumb-quenosomos-6.png';

const QueNoSomos = () => {
  return (
    <>
      <div className="commu-main-talentos__quenosomos">
        <Breadcrumb parent={'Talentos'} title={'¿Qué NO somos?'} />
        <h1 className="wow fadeIn" data-wow-delay=".3s">SOLO REPRESENTANTES</h1>
        <p>
          No es lo que somos. Nuestro trabajo no termina en la <br />
          representación y firma de un contrato, también nos preocupamos <br />
          por tu bienestar, capacitación y crecimiento profesional.
        </p>
        <div className="commu-main-talentos__quenosomos__grid">
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb1} alt="" />
            </div>
          </div>
          <div>Somos</div>
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb2} alt="" />
            </div>
          </div>
          <div>una</div>
          <div>comuunidad</div>
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb3} alt="" />
            </div>
          </div>
          <div>Aprende</div>
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb4} alt="" />
            </div>
          </div>
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb5} alt="" />
            </div>
          </div>
          <div>Crece</div>
          <div>
            <div className="commu-main-talentos__quenosomos__grid__cover">
              <img src={thumb6} alt="" />
            </div>
          </div>
          <div>Desarrolla tu <br />talento</div>
        </div>
        <div className="commu-main-talentos__quenosomos__gridmobile">
          <img src={thumb2} alt="" />
          <h1>Somos una comuunidad, aprende, crece y desarrolla tu talento...</h1>
        </div>
      </div>
    </>
  );
};

export default QueNoSomos;
