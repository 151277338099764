import React from 'react';
import './Logo.scss';

const Logo = (props) => {
  const { color } = props;
  // D06852
  return (
    <>
      <svg
        width="200"
        height="28"
        viewBox="0 0 200 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="header__logo"
      >
        <path
          d="M52.1528 0.557617H37.7532V14.2106C37.7532 21.7402 44.2115 27.8637 52.1528 27.8637C60.0941 27.8637 66.5525 21.7402 66.5525 14.2106C66.5525 6.68111 60.0941 0.557617 52.1528 0.557617ZM52.1528 23.3838C46.8194 23.3838 42.4781 19.2675 42.4781 14.2106V5.03752H52.1528C57.4862 5.03752 61.8276 9.15379 61.8276 14.2106C61.8276 19.2675 57.4862 23.3838 52.1528 23.3838Z"
          fill={color}
        />
        <path
          d="M52.1528 16.0967C51.0534 16.0967 50.1636 15.2482 50.1636 14.2106H45.4387C45.4387 17.7209 48.4506 20.5814 52.1579 20.5814C55.8652 20.5814 58.8771 17.7257 58.8771 14.2106H54.1522C54.1419 15.253 53.2471 16.0967 52.1528 16.0967Z"
          fill={color}
        />
        <path
          d="M100.547 15.5633V27.3352H94.7792V15.5633C94.7792 9.14894 93.0713 5.41084 88.0702 5.41084C83.0692 5.41084 81.3613 9.14894 81.3613 15.5633V27.3352H75.5933V15.5633C75.5933 5.80356 79.8273 0.557617 88.0651 0.557617C92.3605 0.557617 95.5973 2.23031 97.653 5.0763C99.77 2.23031 102.945 0.557617 107.241 0.557617C115.479 0.557617 119.713 5.79871 119.713 15.5633V27.3352H113.95V15.5633C113.95 9.14894 112.242 5.41084 107.241 5.41084C102.255 5.41084 100.547 9.14894 100.547 15.5633Z"
          fill={color}
        />
        <path
          d="M130.564 12.2713V0.557617H136.332V12.2713C136.332 18.9669 139.451 22.7001 144.922 22.7001C150.394 22.7001 153.513 18.962 153.513 12.2713V0.557617H159.281V12.2713C159.281 22.1426 153.692 27.3303 144.927 27.3303C136.153 27.3352 130.564 22.1474 130.564 12.2713Z"
          fill={color}
        />
        <path
          d="M171.282 12.2713V0.557617H177.051V12.2713C177.051 18.9669 180.17 22.7001 185.641 22.7001C191.113 22.7001 194.232 18.962 194.232 12.2713V0.557617H200V12.2713C200 22.1426 194.411 27.3303 185.646 27.3303C176.872 27.3352 171.282 22.1474 171.282 12.2713Z"
          fill={color}
        />
        <path
          d="M15.2383 23.3158C9.53161 23.3158 5.76805 19.0784 5.76805 13.9439C5.76805 8.64465 9.77194 4.57202 15.2383 4.57202C18.7769 4.57202 21.6558 6.21562 23.2921 8.64465L27.7051 5.76472C24.9744 2.30298 20.4848 0 15.2383 0C6.47372 0 0 6.36107 0 13.9439C0 21.5316 6.47372 27.8878 15.2383 27.8878C21.3541 27.8878 26.2835 24.8334 28.7687 20.562L23.9057 18.1911C22.4433 21.1922 19.2626 23.3158 15.2383 23.3158Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default Logo;
