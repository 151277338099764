import React from 'react';
import './ButtonMarketplace.scss';

const ButtonMarketplace = () => {
  return (
    <>
      <div className="commu-button-marketplace">
        CREATOR’S MARKETPLACE
      </div>
    </>
  );
};

export default ButtonMarketplace;
