import React from 'react';
import { Link } from 'react-router-dom';
import './Home.scss';
import homeGirl from '../../assets/images/home-girl.png';
import homeBoy from '../../assets/images/home-boy.png';
import homeBg from '../../assets/images/home-bg.png';
import homeBg2 from '../../assets/images/home-bg-2.png';
import homeArc from '../../assets/images/home-arc.png';
import logo from '../../assets/images/logo.svg';
import logoWhite from '../../assets/images/logo-white.svg';
import TalentoShadow from '../../assets/images/TALENTOS.svg';
import MarcasShadow from '../../assets/images/MARCAS.svg';
import Onfire from '../../assets/images/onfire.svg';
import Facebook from '../../assets/images/icon-fb.svg';
import Instagram from '../../assets/images/icon-instagram.svg';
import Linkedin from '../../assets/images/icon-linkedin.svg';
import ButtonMarketplace from '../../components/ButtonMarketplace';
import HoverImage from '../../components/HoverImage';

const Home = () => {

  return (
    <>
      <main className="commu-main-intro">
        <header className="commu-main-intro__header">
          <img src={logo} alt="" />
          <Link to="/marketplace">
            <ButtonMarketplace />
          </Link>
        </header>
        <div className="commu-header-intro-mobile">
          <Link to="/"><img src={logoWhite} alt="" /></Link>
        </div>
        <section className="commu-main-intro__wrapper">
          <div className="commu-main-intro__wrapper__col">
            <h1>
              <span>
                <Link to={'/talentos/que-somos'}>
                  Talentos
                  <img src={TalentoShadow} alt='shadow talentos' />
                </Link>
              </span>
            </h1>
            <hr />
            <p>¿Actúas, cantas o creas contenido?</p>
          </div>
          <div className="commu-main-intro__wrapper__col images">
            <HoverImage src={homeGirl} alt="" />
            <div className="girl-border" />
            <HoverImage src={homeBoy} alt="" />
            <div className="boy-border" />
            <img src={homeBg} alt="" />
            <img src={homeBg2} alt="" />
            <img src={homeArc} alt="" />
          </div>
          <div className="commu-main-intro__wrapper__col">
            <h1>
              <span>
                <Link to={'/marcas/que-somos'}>
                  Marcas
                  <img src={MarcasShadow} alt='shadow marcas' />
                </Link>
              </span>
            </h1>
            <hr />
            <p>¿Buscas un talento que represente a tu marca y funcione estratégicamente?</p>
          </div>
        </section>
        <footer className="commu-main-intro__footer">
          <a
            className="commu-main-intro__footer__text"
            href="https://www.onfire.co/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Powered by:</p>
            <img src={Onfire} alt='onfire' />
          </a>
          <div className="commu-main-intro__footer__links">
            <a href="https://facebook.com">
              <img src={Facebook} alt='facebook' />
            </a>
            <a href="https://instagram.com">
              <img src={Instagram} alt='instagram' />
            </a>
            <a href="https://linkedin.com">
              <img src={Linkedin} alt='linkedin' />
            </a>
          </div>
        </footer>
      </main>
    </>
  );
};

export default Home;
