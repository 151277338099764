import { useState, useEffect } from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import TalentoSlider from '../../components/TalentoSlider';


const Talentos = () => {
  const [query, setQuery] = useState('')
  const [value, setValue] = useState('')
  const handleChange = (ev) => {
    setValue(ev.target.value)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (query !== value) {
        setQuery(value)
      }
    }, 1500);
    return () => clearTimeout(timer)
  }, [value])
  return (
    <div className="commu-main-marcas__talentos">
      <Breadcrumb parent={'Marcas'} title={'Talentos'} />
      <div className="commu-main-marcas__talentos__title">
        <h1>Ellos forman parte de comuu</h1>
        <input
          placeholder='Buscar talento'
          type='search'
          value={value}
          onChange={handleChange}
        />
      </div>
      <TalentoSlider query={query} />
    </div>
  )
}

export default Talentos
