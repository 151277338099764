import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Talentos from './pages/Talentos';
import Marcas from './pages/Marcas';
import Marketplace from './pages/Marketplace';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/talentos" component={Talentos} />
        <Route path="/marcas" component={Marcas} />
        <Route exact path="/marketplace" component={Marketplace} />
      </Switch>
    </Router>
  );
}

export default App;
