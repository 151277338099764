import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb from '../../assets/images/thumb-talentos-1.png';
import circle from '../../assets/images/circulo-talento.png';

const QueSomos = () => {
  return (
    <>
      <div className="commu-main-talentos__quesomos">
        <div className="commu-main-talentos__quesomos__col" >
          <Breadcrumb parent={'Talentos'} title={'¿Qué somos?'} />
          <h1 className="wow fadeIn" data-wow-delay=".3s">BUSCAMOS PULIR TU TALENTO</h1>
          <p>¿La gente que te sigue alguna vez viajó contigo a través de tu celular porque subiste stories o buenas fotos?</p>
          <p>¿Vieron una película que recomendaste? ¿Pusieron un playlist que compartiste? ¿Comentan lo que subes?</p>
          <p><b>TIENES UN TALENTO</b> y queremos representarte, pero más que eso, hacerte crecer.</p>
        </div>
        <div style={{paddingTop: '5rem'}}>
          <img src={circle} alt="" />
          <img src={thumb} alt="" className="img-full wow slideInUp" />
        </div>
      </div>
    </>
  );
};

export default QueSomos;
