import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const HoverImage = (props) => {
  let hover = false
  let data = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
  const element = useRef(null)
  const calculatePosition = async () => {
    gsap.set(element.current, {
      x: 0,
      y: 0,
      scale: 1
    });
    const box = element.current.getBoundingClientRect();
    let x = box.left + (box.width * 0.5);
    let y = box.top + (box.height * 0.5);
    let width = box.width;
    let height = box.height;
    data = { x, y, width, height }
  }
  const onMouseMove = (e) => {
    let hoverLocal = false;
    let hoverArea = (hover ? 0.7 : 0.5);
    let x = e.clientX - data.x;
    let y = e.clientY - data.y;
    let distance = Math.sqrt( x*x + y*y );
    // console.log(distance)
    // console.log(data.width * hoverArea)
    if (distance < (data.width * hoverArea)) {
      hoverLocal = true;
        if (!hover) {
          hover = true
        }
        onHover(e.clientX, e.clientY);
    }
    if(!hoverLocal && hover) {
      onLeave();
      hover = false
    }
  }
  const onHover = (x, y) => {
    gsap.to(element.current,  {
      x: (x - data.x) * 0.2,
      y: (y - data.y) * 0.2,
      scale: 1.05,
      ease: 'power2.out',
      duration: 0.4
    });
  }
  const onLeave = () => {
    gsap.to(element.current, {
      x: 0,
      y: 0,
      scale: 1,
      ease: 'elastic.out(1.2, 0.4)',
      duration: 0.7
    });
  }
  useEffect(() => {
    calculatePosition()
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('resize', calculatePosition);
    element.current.addEventListener('animationend', calculatePosition);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', calculatePosition);
    }
  }, [])
  return (
    <img {...props} ref={element} alt="Commu" />
  )
}

export default HoverImage