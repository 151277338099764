import React from 'react';
import { Link } from 'react-router-dom';
import './Marketplace.scss';
import Logo from '../../components/Logo';
import thumb from '../../assets/images/thumb-marketplace.png';
import messageIcon from '../../assets/images/message.svg';
import Onfire from '../../assets/images/onfire-white.svg';
import Facebook from '../../assets/images/icon-fb-white.svg';
import Instagram from '../../assets/images/icon-instagram-white.svg';
import Linkedin from '../../assets/images/icon-linkedin-white.svg';

const Marketplace = () => {
  const [email, setEmail] = React.useState('')
  const sendSubscription = async () => {
    if (!email) return
    const res = await fetch('https://admin.somoscomuu.com/wp-json/custom/v1/saveSubscription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    })
    const { post_id } = await res.json()
    if (post_id) {
      setEmail('')
      alert('Recibimos su correo')
    } else {
      alert('No se pudo enviar los datos')
    }
  }
  return (
    <>
      <main className="commu-main-marketplace wow fadeIn">
        <div className="commu-main-marketplace__header">
          <Link to="/">
            <Logo color={'#FFF'} />
          </Link>
        </div>
        <div className="commu-main-marketplace__wrapper">
          <div>
            <h1>Se viene el marketplace de creadores de contenido más importante y grande de Latinoamérica. </h1>
            <h2>¿Quieres ser el primero en enterarte?<br />¡SUSCRÍBETE !</h2>
            <div className="commu-main-marketplace__wrapper__textfield">
              <img src={messageIcon} alt="" />
              <input
                type='email'
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                placeholder='ESCRIBE TU EMAIL'
              />
              <button onClick={sendSubscription}>
                OK
              </button>
            </div>
          </div>
          <div>
            <img src={thumb} alt="" />
          </div>
        </div>
        <footer className="commu-main-marketplace__footer">
          <a
            className="commu-main-marketplace__footer__text"
            href="https://www.onfire.co/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Powered by:</p>
            <img src={Onfire} alt='onfire' />
          </a>
          <div className="commu-main-marketplace__footer__right">
            <p>© 2021 - All rights reserved - comuu</p>
          </div>
          <div className="commu-main-marketplace__footer__links">
            <a href="https://facebook.com">
              <img src={Facebook} alt='facebook' />
            </a>
            <a href="https://instagram.com">
              <img src={Instagram} alt='instagram' />
            </a>
            <a href="https://linkedin.com">
              <img src={Linkedin} alt='linkedin' />
            </a>
          </div>
        </footer>
      </main>
    </>
  );
};

export default Marketplace;
