import React from 'react';
import './Breadcrumb.scss';

const Breadcrumb = (props) => {
  const { parent, title, color } = props;

  return (
    <>
      <div className="commu-breadcrumb">
        <div style={{ color: color }}>
          {`${parent}`} <i>{`//`}</i> <span>{title}</span>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
