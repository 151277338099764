import { useHistory } from "react-router-dom"
import facebookIcon from "../../assets/images/icon-fb.svg"
import instagramIcon from "../../assets/images/icon-instagram.svg"
import linkedinIcon from "../../assets/images/icon-linkedin.svg"
import arrowRightWhite from "../../assets/images/arrow-right-white.svg"
import arrowLeftWhite from "../../assets/images/arrow-left-white.svg"
import arrowRight from "../../assets/images/arrow-right.svg"
import arrowLeft from "../../assets/images/arrow-left.svg"
import './Footer.scss';

const Footer = ({ view, section, isBlack = false }) => {
  const history = useHistory()
  const handleBack = () => {
    if (view === 'que-hacemos') {
      history.push(`/${section}/que-somos`)
    } else if (view === 'servicios') {
      history.push(`/${section}/que-hacemos`)
    } else if (view === 'talentos') {
      history.push(`/${section}/servicios`)
    } else if (view === 'que-no-somos') {
      if (section === 'talentos') {
        history.push(`/${section}/servicios`)
      } else {
        history.push(`/${section}/talentos`)
      }
    } else if (view === 'contacto') {
      history.push(`/${section}/que-no-somos`)
    }
  }
  const handleNext = () => {
    if (view === 'que-somos') {
      history.push(`/${section}/que-hacemos`)
    } else if (view === 'que-hacemos') {
      history.push(`/${section}/servicios`)
    } else if (view === 'servicios') {
      if (section === 'talentos') {
        history.push(`/${section}/que-no-somos`)
      } else {
        history.push(`/${section}/talentos`)
      }
    } else if (view === 'talentos') {
      history.push(`/${section}/que-no-somos`)
    } else if (view === 'que-no-somos') {
      history.push(`/${section}/contacto`)
    }
  }
  return (
    <div className={`commu-footer ${isBlack ? 'black' :''}`}>
      <div className="commu-footer__wrapper">
        <div className="commu-footer__wrapper__pages">
          <span className={view === 'que-somos' ? 'active' : ''}>01</span>
          <span className={view === 'que-hacemos' ? 'active' : ''}>02</span>
          <span className={view === 'servicios' ? 'active' : ''}>03</span>
          <span className={view === 'talentos' ? 'active' : ''}>04</span>
          <span className={view === 'que-no-somos' ? 'active' : ''}>05</span>
          <span className={view === 'contacto' ? 'active' : ''}>06</span>
        </div>
        <div className="commu-footer__wrapper__buttons">
          <button onClick={handleBack}>
            {isBlack
              ? <img src={arrowLeftWhite} alt='left' />
              : <img src={arrowLeft} alt='left' />}
          </button>
          <button onClick={handleNext}>
            {isBlack
              ? <img src={arrowRightWhite} alt='right' />
              : <img src={arrowRight} alt='right' />}
          </button>
        </div>
        {isBlack
          ? <div className="commu-footer__wrapper__links">
            <span>© 2021 - All rights reserved - comuu</span>
          </div>
          : <div className="commu-footer__wrapper__links">
          <a href="https://facebook.com">
            <img src={facebookIcon} alt='facebook' />
          </a>
          <a href="https://instagram.com">
            <img src={instagramIcon} alt='instagram' />
          </a>
          <a href="https://linkedin.com">
            <img src={linkedinIcon} alt='linkedin' />
          </a>
        </div>}
      </div>
    </div>
  )
}

export default Footer
