import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb from '../../assets/images/thumb-marcas-2.png';

const QueHacemos = () => {
  return (
    <>
      <div className="commu-main-marcas__quehacemos">
        <Breadcrumb parent={'Marcas'} title={'¿Qué hacemos?'} />
        <h1><span>REPRESENTAMOS, ASESORAMOS, <br />CAPACITAMOS Y PRODUCIMOS <br />CONTENIDO</span></h1>
        <p>Nos enfocamos en desarrollar y pulir  talentos, con la finalidad de <br />tener contenido de valor que sume a la estrategia de tu marca</p>
        <div className="commu-main-marcas__quehacemos__thumbnail">
          <img src={thumb} alt="" />
        </div>
        <p style={{textAlign: 'right'}}>con resultados medibles en tiempo real y optimizables según los KPI’s planteados.</p>
      </div>
    </>
  );
};

export default QueHacemos;
