import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import thumb from '../../assets/images/thumb-quenosomos.png';
import thumbLines from '../../assets/images/thumb-quenosomos-lines.png';

const QueNoSomos = (props) => {
  return (
    <>
      <div className="commu-main-marcas__quenosomos">
        <Breadcrumb parent={'Marcas'} title={'¿Qué NO somos?'} />
        <h1>NO SOLO REPRESENTAMOS</h1>
        <p>
          Nuestro trabajo no solo se trata de buscar un talento que funcione <br />
          para tu marca, también aseguramos la creación de contenido de calidad <br />
          en base a una estrategia de marketing, vamos más allá de la
          representación.
        </p>
        <div className="commu-main-marcas__quenosomos__thumbnail">
          <img src={thumb} alt="" />
          <img src={thumbLines} alt="" />
        </div>
        
      </div>
    </>
  );
};

export default QueNoSomos;
